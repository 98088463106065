import React from "react"
import { Link } from 'gatsby'

import Layout from "~components/layout/layout.component"
import CreateOrganizationForm from '~components/create-organization-form/create-organization-form.component';
import SEO from "~components/seo/seo.component"

const CreateOrganizationPage = () => {
  return (
    <Layout>
      <SEO title="Create Organization Account" />
      <h1>Create Organization Account</h1>
      <CreateOrganizationForm />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
    )
  }
  
  export default CreateOrganizationPage
  
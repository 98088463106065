import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import Button from '~components/button/button.component';

import { cleanRequestResponse, getUrl, isTagNode, redirectUrl } from '~common/legacy-helpers';

import useStyles from './create-organization-form.styles';

const CreateOrganizationForm = props => {
  const classes = useStyles(props);
  let windowLocationOrigin = useRef();
  const [pageMarkup, setPageMarkup] = useState('');

  useEffect(() => {
    windowLocationOrigin.current = window.location.origin;
    async function fetchPage() {
      try {
        const data = await getUrl(`https://www.rainedout.net/create_new_account.php`)
        setPageMarkup(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPage();
  }, []);

  const customRequestResponse = (node, index) => {
    if (isTagNode(node) && node.name === 'input' && node.attribs.type === 'submit') {
      return (
        <>
          <Button inputSubmit={true} text="Submit" key="button" />
          {/* Glue redirect field that's processed by the receiving form action php file where a redirect is performed. */}
          <input type="hidden" id="redirect_url" name="redirect_url" defaultValue={redirectUrl(`${windowLocationOrigin.current}/pick-a-plan`)} />
          <p style={{ marginTop: 20 }}>
            Information that you supply here will be stored by us and used to provide the services you are signing up for.<br/>
            You can opt-out of RainedOut's services by clicking the Unsubscribe button at the bottom of our website.
          </p>
        </>
      )
    }

    if (isTagNode(node)) {
      const cleanNode = cleanRequestResponse(node);
      if (cleanNode) {
        return convertNodeToElement(cleanNode, index, customRequestResponse);
      } else if (cleanNode === null) {
        return null;
      }
    }
  }

  return (
    <div className={classes.createOrganizationForm}>
      {pageMarkup ? ReactHtmlParser(pageMarkup, { transform: customRequestResponse }) : null}
    </div>
  )
}

export default CreateOrganizationForm

import {createUseStyles} from 'react-jss'

import {
  buttonSmall,
  input,
  inputLegacy,
  requiredLegacy,
  select,
  textarea
} from '~styles/mixins.styles'

export default createUseStyles({
  /**
   * jss-plugin-global uses `@global` decorator allowing us
   * to style against the php form's search results.  These
   * class names are defined by the old php form.
   */
  '@global': {
    '.fieldBox': {
      ...requiredLegacy,
      border: 'none',

      '&:not(:last-of-type)': {
        marginBottom: 30
      },

      '& input[type=text]': {
        ...input,
        ...inputLegacy()
      },
      '& input[type=password]': {
        ...input,
        ...inputLegacy()
      },
      '& textarea': {
        ...textarea,
        ...inputLegacy()
      },
      '& select': {
        ...select,
        ...inputLegacy()
      },

      '& [type=submit]': {
        ...buttonSmall
      },
    },

    '.labelBox': {
      display: 'block',
      marginBottom: 5
    },

    '[type=checkbox] + .labelBox': {
      display: 'initial',
      marginLeft: 5
    },

    // Hide these as opposed to attempting to parse through the nodes.
    '.content': {
      '& > div:nth-child(1)': {
        display: 'none'
      },
      '& > h3:nth-child(2)': {
        display: 'none'
      },
      '& > div:nth-child(3)': {
        display: 'none'
      },
      '& > div:nth-child(4)': {
        display: 'none'
      },
      '& > div:last-child': {
        display: 'none'
      }
    },

    '.required_field': {
      marginBottom: 20
    },

    createOrganizationForm: {}
  }
})